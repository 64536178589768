export default [
  {
    header: 'Game Admin',
    icon: 'AwardIcon',
    route: 'game-list',
    children: [
      {
        title: 'Games',
        route: 'game-list',
        icon: 'AwardIcon',
      },
      {
        title: 'Teams',
        route: 'team-list',
        icon: 'UsersIcon',
      },
      {
        title: 'Habits',
        route: 'activity-list',
        icon: 'ActivityIcon',
      },
      {
        title: 'Sets',
        route: 'set-list',
        icon: 'BoxIcon',
      },
      {
        title: 'KPI',
        route: 'kpi-list',
        icon: 'ClockIcon',
      },
      {
        title: 'Goals',
        route: 'goals-list',
        icon: 'TargetIcon',
      },
      {
        title: 'Charity',
        route: 'charity-list',
        icon: 'GiftIcon',
      },
    ],
  },
]
